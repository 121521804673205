// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bx_k3";
export var caseStudyBackground__lineColor = "bx_k0";
export var caseStudyCta__bgColor = "bx_l4";
export var caseStudyHead__imageWrapper = "bx_kX";
export var caseStudyProjectsSection = "bx_k4";
export var caseStudyQuote__bgDark = "bx_l3";
export var caseStudyQuote__bgLight = "bx_kY";
export var caseStudyQuote__bgRing = "bx_kZ";
export var caseStudySolution__ring = "bx_k8";
export var caseStudySolution__ringThree = "bx_l5";
export var caseStudySolution__ringTwo = "bx_k9";
export var caseStudyVideo = "bx_k1";
export var caseStudyVideo__ring = "bx_k2";
export var caseStudy__bgDark = "bx_kV";
export var caseStudy__bgLight = "bx_kT";